import Editor from 'components/Comments/Editor';
import UploadedFile from 'components/Comments/Editor/UploadedFile';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SButton from 'components/Standard/SButton';
import { setEditingCommentId } from 'redux/ui/clientInteractionPage/reducer';
import { isEmpty, isEqual } from 'lodash';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { InlineCommentText } from 'components/Comments/InlineCommentText';
import { removeCommentsToSave } from 'redux/ui/checklistEditor/reducer';
import { getTranscriptionWithParts } from 'redux/selectors/phoneCallTranscriptions';

const CommentText = ({
  commentState,
  setCommentState,
  isEditing,
  setEditing,
  onUpdate,
  setInitialCommentState,
  disabled,
  onDeleteComment,
  communication
}) => {
  const mounted = React.useRef(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialCommentState] = useState(commentState);
  const commentText = commentState?.text;

  const transcription = useSelector(
    state => getTranscriptionWithParts(state, communication?.phoneCallTranscriptionId),
    isEqual
  );

  const transcriptionParts = transcription?.parts || [];

  // const findMatchingText = (commentText, transcription) => {
  //   // Извлечение встраиваемого текста из commentText
  //   const regex = /'([^']+)'/; // Регулярное выражение для поиска текста внутри одинарных кавычек
  //   const match = commentText.match(regex); // Поиск совпадения
  //
  //   if (match && match[1]) {
  //     const extractedText = match[1]; // Извлечение текста
  //
  //     // Поиск в транскрипции
  //     for (const part of transcription) {
  //       if (part.text.toLowerCase().includes(extractedText.toLowerCase())) {
  //         return part.text; // Возвращаем найденное предложение
  //       }
  //     }
  //   }
  //
  //   return null; // Возвращаем null, если нет совпадений
  // };

  const longestCommonSubstring = (str1, str2) => {
    const matrix = Array(str1?.length + 1)
      .fill(null)
      .map(() => Array(str2?.length + 1).fill(0));
    let maxLength = 0;
    let endIndex = 0;

    for (let i = 1; i <= str1.length; i++) {
      for (let j = 1; j <= str2.length; j++) {
        if (str1[i - 1] === str2[j - 1]) {
          matrix[i][j] = matrix[i - 1][j - 1] + 1;
          if (matrix[i][j] > maxLength) {
            maxLength = matrix[i][j];
            endIndex = i;
          }
        }
      }
    }

    return str1.substring(endIndex - maxLength, endIndex);
  };

  const findMatchingText = (commentText, transcription) => {
    let longestMatch = '';

    for (const part of transcription) {
      const transcriptionText = part.text;

      const regex = /'([^']+)'/; // Регулярное выражение для поиска текста внутри одинарных кавычек
      const commentTextMatch = commentText.match(regex); // Поиск совпадения

      const str1 = commentTextMatch && commentTextMatch[1] ? commentTextMatch[1] : '';

      const match = longestCommonSubstring(str1, transcriptionText);
      // const match = longestCommonSubstring(commentText, transcriptionText);

      if (match.length > longestMatch.length) {
        longestMatch = match;
      }
    }

    const foundTranscriptionComment = transcription.find(part =>
      part?.text.toLowerCase().includes(longestMatch.toLowerCase())
    );

    return longestMatch.length > 0 ? foundTranscriptionComment?.text : null;
  };

  // Находим подстроку которая встречается в обоих случаях
  const commentTranscription = findMatchingText(commentText, transcriptionParts);

  // Избавляемся от html тегов в тексте комментария
  const parser = new DOMParser();
  const doc = parser.parseFromString(commentText, 'text/html');
  const extractedText = doc.body.textContent || '';

  // Находим наиболее длинную подстроку которая встречается в обоих текстах
  const findCommonSubstring = (commentTranscription, extractedText) => {
    if (!commentTranscription || !extractedText) return;
    let longest = '';

    for (let i = 0; i < commentTranscription.length; i++) {
      for (let j = i + 1; j <= commentTranscription.length; j++) {
        const substring = commentTranscription.slice(i, j);
        if (
          extractedText.toLowerCase().includes(substring.toLowerCase()) &&
          substring.length > longest.length
        ) {
          longest = substring;
        }
      }
    }

    return longest;
  };

  const commonSubstring = findCommonSubstring(commentTranscription, extractedText);

  useEffect(() => {
    // Will set it to true on mount ...
    mounted.current = true;
    return () => {
      // ... and to false on unmount
      mounted.current = false;
    };
  }, []);

  const onDeleteFile = async id => {
    await setCommentState({
      ...commentState,
      uploadedFiles: commentState?.uploadedFiles.filter(({ id: fileId }) => fileId !== id)
    });
    await onUpdate({
      id: commentState?.id,
      uploadedFilesIds: commentState?.uploadedFiles
        .filter(({ id: fileId }) => fileId !== id)
        .map(({ id }) => id)
    });
  };

  const confirmUpdate = async id => {
    await onUpdate({
      id,
      text: commentText,
      metadata: {
        ...commentState?.metadata,
        ...(commentState?.metadata?.ratingFlag && {
          ratingFlag: commentState?.metadata?.ratingFlag
        }),
        ...(commentState?.metadata?.questionId && {
          questionId: commentState?.metadata?.questionId
        })
      },
      hidden: commentState?.hidden,
      uploadedFilesIds: commentState?.uploadedFiles?.map(file => file?.id)
    });
    setEditing(!isEditing);
    setCommentState({ ...commentState });
    dispatch(setEditingCommentId(null));
    dispatch(removeCommentsToSave(id || commentState?.id));
    mounted.current && setInitialCommentState(initialCommentState);
  };

  const handleCancelClick = id => {
    dispatch(setEditingCommentId(id));
    dispatch(removeCommentsToSave(id || commentState?.id));
    setEditing(!isEditing);
    setCommentState(initialCommentState);
  };

  const getCommentTextWithAnchor = () => {
    if (commentTranscription) {
      let commentWithAnchor = '';

      // Убираем в конце точку у сообщения из транскрибации
      const commentTranscriptionWithoutDot = commentTranscription.replace(/\.$/, '');

      // Если найдена строка которая есть в проверке и в транскрибации
      if (commonSubstring) {
        // commentWithAnchor = commentText.toLowerCase().replace(
        //   commonSubstring.toLowerCase(),
        //   `<a href="#${
        //     // Если сообщение транскрибации не равно найденной строке то нужно подставлять полное сообщение транскрибации т.к. якорь имеет id которое равно полному сообщению транскрибации
        //     commentTranscriptionWithoutDot.toLowerCase() !== commonSubstring.toLowerCase()
        //       ? commentTranscriptionWithoutDot.toLowerCase()
        //       : commonSubstring.toLowerCase()
        //   }">${commonSubstring}</a>`
        // );
        const commentTextOriginal = commentText; // Сохраняем оригинальный текст
        const commonSubstringLower = commonSubstring.toLowerCase();
        const commentTextLower = commentText.toLowerCase();

        const startIndex = commentTextLower.indexOf(commonSubstringLower);
        const endIndex = startIndex + commonSubstring.length;

        // Извлекаем оригинальную подстроку с учётом регистра
        const originalSubstring = commentTextOriginal.substring(startIndex, endIndex);

        // Формируем ссылку, используя оригинальный регистр для самой подстроки
        const anchor = `<a href="#${
          commentTranscriptionWithoutDot.toLowerCase() !== commonSubstringLower
            ? commentTranscriptionWithoutDot.toLowerCase()
            : commonSubstringLower
        }">${originalSubstring}</a>`;

        // Заменяем подстроку с учетом регистра в оригинальном тексте
        commentWithAnchor = commentTextOriginal.replace(originalSubstring, anchor);
      }
      return commentWithAnchor;
    }
    return commentText;
  };

  const handleAnchorClick = event => {
    if (event.target.tagName === 'A' && event.target.href.includes('#')) {
      event.preventDefault(); // предотвращаем стандартное поведение
      const targetId = event.target.getAttribute('href').substring(1);
      const element = document.getElementById(targetId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' });
      }
    }
  };

  return (
    <StyledRow>
      {!isEditing && (
        <SRow gutter={[0, 8]} style={{ margin: '-4px' }}>
          <SCol span={24} padding="4px">
            <InlineCommentText
              onClick={handleAnchorClick}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(getCommentTextWithAnchor(), {
                  ALLOWED_ATTR: ['target', 'href']
                })
              }}
            />
          </SCol>
          <SCol span={24}>
            {commentState?.uploadedFiles?.map(file => (
              <UploadedFile
                disabled={disabled}
                key={file?.id}
                uploadedFile={file}
                allFiles={commentState?.uploadedFiles}
                onDelete={onDeleteFile}
                onDeleteComment={onDeleteComment}
                commentState={commentState}
              />
            ))}
          </SCol>
        </SRow>
      )}
      {isEditing && (
        <SRow>
          <SCol width="100%">
            <Editor
              commentState={commentState}
              setCommentState={setCommentState}
              showTemplates
              showFlags
              showAttachments
              allowHidden
              actionsComponent={[
                <SButton key="cancel" onClick={() => handleCancelClick(null)} marginRight="6px">
                  {t('components.commentList.comment.commentEditButton.buttons.cancel')}
                </SButton>,
                <SButton
                  key="save"
                  type="primary"
                  onClick={() => confirmUpdate(commentState?.id)}
                  disabled={isEmpty(commentText)}
                  className="BraftEditor-actions"
                >
                  {t('components.commentsPanel.buttons.send')}
                </SButton>
              ]}
            />
          </SCol>
        </SRow>
      )}
    </StyledRow>
  );
};

const StyledRow = styled(SRow)`
  &.ant-row {
    display: block;
  }
  &.ant-row > span > p {
    margin: 0px;
  }
`;

export default CommentText;
