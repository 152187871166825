import React from 'react';
import { INTEGRATIONS } from 'core/utils/constants';
import decamelizeKeys from 'decamelize-keys';
import { integrationsResource } from 'redux/resources/integrations';
import api, { naumenSynchronizationEndpoint } from 'core/api';
import { processJsonApiCollection } from 'core/jsonapi';
import { Col, message, Modal, Row, Spin, Typography } from 'antd';
import CryptoJS from 'crypto-js';
import { camelCase } from 'lodash';
import { saveBackgroundJob } from '../backgroundJob/operations';
import parseOptionsForApi from '../../../core/utils/parseOptionsForApi';
import { naumenIntegrationKey } from '../../../core/config';

let integration = null;
let errorInstall = true;
const modal = Modal;
export const createNaumenIntegration = ({
  name,
  endpoint,
  clientSecret,
  username,
  password,
  organization_id,
  mysqlHost,
  mysqlPort,
  mysqlDatabase,
  mysqlUsername,
  mysqlPassword
}) => async dispatch => {
  try {
    await (async function() {
      integration = await dispatch(
        integrationsResource.operations.create({
          name,
          endpoint,
          clientSecret,
          password,
          username,
          integrationType: INTEGRATIONS.naumen.type
        })
      );
      modal.confirm({
        title: 'Внимание',
        content: (
          <Row>
            <Col>
              <Typography.Text>Подождите, идет установка интеграции</Typography.Text>
            </Col>
            <Col style={{ width: '100%', textAlign: 'center' }}>
              <Spin />
            </Col>
          </Row>
        ),
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { style: { display: 'none' } },
        okText: 'Ok',
        closable: false,
        maskClosable: false
      });
      await api
        .createNaumenIntegration(
          parseOptionsForApi({
            name,
            endpoint,
            clientSecret,
            password,
            username,
            state: organization_id,
            integrationType: INTEGRATIONS.naumen.type,
            mysqlHost,
            mysqlPort,
            mysqlDatabase,
            mysqlUsername,
            mysqlPassword
          })
        )
        .then(() => (errorInstall = true))
        .catch(() => (errorInstall = false));
    })();
  } catch (error) {
    console.log(error);
  } finally {
    modal.destroyAll();
    console.log(errorInstall);
    if (integration && errorInstall) {
      dispatch(saveBackgroundJob(integration.currentBackgroundJob));
      return integration.id;
    }
    dispatch(integrationsResource.operations.deleteById({ id: integration.id }));
  }
};

export const syncDataWithNaumen = ({ id, fetchFrom }) => async dispatch => {
  await fetch(`${naumenSynchronizationEndpoint}?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      integration_id: id,
      fetch_from: fetchFrom
    })
  });
};

export const getEntityProject = async integrationId => {
  try {
    const response = await api.getEntityProject(decamelizeKeys({ integrationId }));

    return response?.body?.data;
  } catch (error) {
    console.log(error);
  }
};

export const connectNaumenProjectById = async (id, integrationId) => {
  try {
    const response = await api.connectEntityProject(parseOptionsForApi({ project_id: id }));
    await api.bindEmployeesNaumen({ integration_id: integrationId });
    return response?.body;
  } catch (error) {
    console.log(error);
  }
};
export const getEntityCustomFieldsNaumen = ({ id }) => async dispatch => {
  try {
    await api.refreshEntityCustomFieldsNaumen(parseOptionsForApi({ integrationId: id }));
    const response = await api.getEntityCustomFieldsNaumen(decamelizeKeys({ integrationId: id }));
    const resources = processJsonApiCollection(response.body.data);
    return resources;
  } catch (error) {}
};
export const connectCustomFieldNaumen = ({ fieldId }) => async dispatch => {
  try {
    await api.connectEntityCustomFields(parseOptionsForApi({ fieldId }));
    return true;
  } catch (error) {}
};

export const connectAllCustomFieldsNaumen = ({ id, all }) => async dispatch => {
  try {
    await api.connectAllEntityCustomFields(parseOptionsForApi({ integrationId: id, connect: all }));
    return true;
  } catch (error) {}
};

export const disconnectCustomFieldNaumen = ({ fieldId }) => async dispatch => {
  try {
    await api.disconnectEntityCustomFields(parseOptionsForApi({ fieldId }));
    return true;
  } catch (error) {}
};

const CryptoJSAesJson = {
  stringify(cipherParams) {
    const j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
    if (cipherParams.iv) j.iv = cipherParams.iv.toString();
    if (cipherParams.salt) j.s = cipherParams.salt.toString();
    return JSON.stringify(j);
  },
  parse(jsonStr) {
    const j = JSON.parse(jsonStr);
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(j.ct)
    });
    if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
    if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
    return cipherParams;
  }
};
export const getCredentialsNaumen = async clientInteractionId => {
  try {
    const response = await api.getNaumenCredentials(
      decamelizeKeys({
        client_interaction_id: clientInteractionId
      })
    );
    const decrypted = JSON.parse(
      CryptoJS.AES.decrypt(response?.body?.data?.password, naumenIntegrationKey, {
        format: CryptoJSAesJson
      }).toString(CryptoJS.enc.Utf8)
    );
    return {
      password: decrypted,
      username: response?.body?.data?.username
    };
  } catch (error) {
    console.log(error);
  }
};

export function decryptedData(str) {
  return JSON.parse(
    CryptoJS.AES.decrypt(str, naumenIntegrationKey, {
      format: CryptoJSAesJson
    }).toString(CryptoJS.enc.Utf8)
  );
}
export const getNaumenDb = async id => {
  try {
    const response = await api.getNaumenDb(decamelizeKeys({ integrationId: id }));
    const { data } = response.body;
    const decryptedDataKeys = [
      'mysql_host',
      'mysql_port',
      'mysql_username',
      'mysql_database',
      'mysql_password'
    ];
    const result = {};
    decryptedDataKeys.forEach(key => {
      result[camelCase(key)] = decryptedData(data[key]);
    });
    return result;
  } catch (error) {}
};
export const setNaumenDb = async (id, otherFields) => {
  try {
    const response = await api.setNaumenDb(
      { integration_id: id },
      decamelizeKeys({
        mysqlHost: otherFields.mysqlHost,
        mysqlPort: otherFields.mysqlPort,
        mysqlUsername: otherFields.mysqlUsername,
        mysqlPassword: otherFields.mysqlPassword,
        mysqlDatabase: otherFields.mysqlDatabase
      })
    );
    const resources = processJsonApiCollection(response.body.data);
    return resources;
  } catch (error) {
    message.error(error);
  }
};

export const getStatusConnectNaumen = async id => {
  try {
    const response = await api.getStatusConnectNaumen(decamelizeKeys({ integrationId: id }));
    return response.body;
  } catch (error) {}
};
