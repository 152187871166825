import api from 'core/api';
import { isEmpty } from 'lodash';
import * as actions from './reducer';
import {
  getDashboardsInLocalStorage,
  setDashboardInLocalStorage,
  setDashboardsInLocalStorage
} from '../../../../core/loacalStorage/operations';

export const getDashboardAnalytics = ({ id, activeDashboard }) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const stateDashboardsRows = state.dashboardRowsResource.byIds;
      const widgetsIds = [];
      activeDashboard.rowsIds.forEach(item => {
        const dashboardRow = stateDashboardsRows[item];
        widgetsIds.push(...dashboardRow.widgetsIds);
      });
      const responseOnLocalStorage = getDashboardsInLocalStorage(widgetsIds);
      let response = [];
      if (isEmpty(responseOnLocalStorage)) {
        response = await api.getDashboardAnalytics({ id });
        response = setDashboardsInLocalStorage(response.body, widgetsIds);
      } else {
        response = responseOnLocalStorage;
      }
      return response.hasOwnProperty('body') ? response.body : response;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
};

export const getDashboardWidgetAnalytics = ({ id }) => {
  return async dispatch => {
    try {
      await dispatch(actions.loadDashboardWidgetAnalyticsStarted({ id }));
      const response = await api.getDashboardWidgetAnalytics({ id });
      const widgetAnalyticsObject = setDashboardInLocalStorage(id, response.body);
      await dispatch(actions.loadDashboardWidgetAnalyticsSucceed(widgetAnalyticsObject));
      return widgetAnalyticsObject;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
};
