import React, { useState } from 'react';
import { Tooltip } from 'antd';
import IconButton from 'components/IconButton';
import { useTranslation } from 'react-i18next';
import { FileTextOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import { getTranscriptionWithParts } from '../../../redux/selectors/phoneCallTranscriptions';

const ExportToTxtButton = ({ reviewId }) => {
  const { t } = useTranslation();
  const [setIsHovered] = useState(false);

  const review = useSelector(state => state.reviewsResource.byIds[reviewId]);
  const clientInteractionById = useSelector(
    state => state.clientInteractionsResource.byIds[review?.clientInteractionId]
  );
  const { currentCallId } = useSelector(state => state.uiRecordPlayer);
  const clientInteraction = useSelector(state => ({
    ...get(state.phoneCallsResource.byIds, review?.clientInteractionId || currentCallId, {}),
    ...get(
      state.textCommunicationsResource.byIds,
      review?.clientInteractionId || currentCallId,
      {}
    ),
    ...clientInteractionById
  }));
  const transcription = useSelector(
    state => getTranscriptionWithParts(state, clientInteraction?.phoneCallTranscriptionId),
    isEqual
  );
  const handleExport = () => {
    if (!transcription) return;

    const { parts } = transcription;
    const { startedAt } = clientInteraction;

    const lines = parts.map(part => {
      const { text, startTime, role } = part;
      const time = new Date(startedAt);
      time.setSeconds(time.getSeconds() + startTime);
      const formattedTime = time.toLocaleString();
      return `[${formattedTime}] ${role.toUpperCase()}: ${text}`;
    });

    const fileContent = lines.join('\n');
    const blob = new Blob([fileContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `transcription_${reviewId}.txt`;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <Tooltip
      title={t('clientInteractionsPage.drawer.tables.menu.exportToTxt')}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <IconButton
        icon={<FileTextOutlined />}
        shape="circle-outline"
        size="small"
        style={{ marginTop: 2 }}
        onClick={handleExport}
      />
    </Tooltip>
  );
};

export default ExportToTxtButton;
