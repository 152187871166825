import { MessageOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Avatar, Button, Col, Empty, Modal, Row, Tooltip, Typography } from 'antd';
import CommentsPanel from 'components/Comments/CommentsPanel';
import SCard from 'components/Standard/SCard';
import UserPreview from 'components/UserPreview';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAddingCommentCommunicationPartId } from 'redux/ui/clientInteractionPage/reducer';
import styled from 'styled-components';
import PartContent from './PartContent';
import EventConversationPart from './EventConversationPart';
import './style.css';
import { get } from 'lodash';
import { getUserName } from 'components/UserPreview/getUserName';

const ConversationPart = React.forwardRef(
  (
    {
      postedAt,
      seconds,
      id,
      operatorId,
      clientId,
      body,
      comments = [],
      allowCommenting = true,
      onAddCommentButton,
      onCommentSave,
      onDeleteComment,
      onUpdateComment,
      contentType,
      partType,
      author,
      editingCommentId
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { Text } = Typography;

    const client = useSelector(state => get(state.clientsResource.byIds, clientId, {}));

    const isEvent = partType === 'event'; // событие - например, кто-то зашел в чат
    const isSystem = partType === 'system'; // системемные сообщение - сообщение бота например
    const isInternal = partType === 'internal_message'; // внутреннее сообщение - как note в intercome
    const isOperator = !isEmpty(operatorId) || (author && author?.type === 'operator') || isSystem;

    const dispatch = useDispatch();

    const addingCommentCommunicationPartId = useSelector(
      state => state.uiClientInteractionPage.addingCommentCommunicationPartId
    );

    const openModal = useCallback(() => dispatch(setAddingCommentCommunicationPartId(id)), [
      dispatch,
      id
    ]);

    const closeModal = useCallback(() => dispatch(setAddingCommentCommunicationPartId(null)), [
      dispatch
    ]);

    const bodyWithoutDot = body.replace(/\.$/, '');

    return (
      <Col span={24} ref={ref}>
        {isEvent ? (
          <EventConversationPart body={body} contentType={contentType} postedAt={postedAt} />
        ) : isOperator ? (
          <Row
            type="flex"
            className="open"
            align="start"
            justify="end"
            gutter={[8, 8]}
            style={{ margin: '-4px' }}
          >
            <CommentsContainer className="hidden" comments={comments} span={1}>
              <Row type="flex" justify="end">
                <Col>
                  <Tooltip
                    title={
                      !isEmpty(comments)
                        ? t(
                            'components.textCommunicationContent.conversationPart.buttons.viewComments'
                          )
                        : t(
                            'components.textCommunicationContent.conversationPart.buttons.addComment'
                          )
                    }
                  >
                    <Button
                      type="link"
                      icon={<MessageOutlined />}
                      onClick={openModal}
                      size="large"
                      style={
                        !isEmpty(comments) ? { color: 'var(--primary)' } : { color: '#A6ADB4' }
                      }
                    />
                  </Tooltip>
                </Col>
              </Row>
            </CommentsContainer>

            <TextContainer id={bodyWithoutDot.toLowerCase()}>
              <SCard
                bodyPadding="8px"
                style={{ background: isSystem || isInternal ? '#f3ebb0' : '#E6F7FF' }}
              >
                <PartContent contentType={contentType}>{body}</PartContent>
              </SCard>
              <Row type="flex" justify="end">
                <Text type="secondary">{moment(postedAt).format('DD/MM/YYYY, HH:mm:ss')}</Text>
              </Row>
            </TextContainer>

            <AvatarCol>
              <Row type="flex" align="middle" justify="center">
                <Col>
                  {!isEmpty(operatorId) ? (
                    <UserPreview
                      avatarSize="default"
                      userId={operatorId}
                      disabled
                      showAvatar
                      disabledInitials
                    />
                  ) : isSystem ? (
                    <Row>
                      <Col>
                        <Badge>
                          <Avatar>System</Avatar>
                        </Badge>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col>
                        <Badge>
                          <Avatar>Unknown</Avatar>
                        </Badge>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </AvatarCol>
          </Row>
        ) : (
          <Row
            type="flex"
            className="open"
            align="start"
            justify="start"
            gutter={[8, 8]}
            style={{ margin: '-4px' }}
          >
            <AvatarCol>
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <Tooltip
                    placement="right"
                    title={client ? getUserName({ user: client }) : ''}
                    // title={client ? `${client.firstName} ${client.lastName}`.trim() : ''}
                  >
                    <Avatar size="default" icon={<UserOutlined />} theme="filled" />
                  </Tooltip>
                </Col>
              </Row>
            </AvatarCol>

            <TextContainer align="left" id={bodyWithoutDot.toLowerCase()}>
              <SCard
                bodyPadding="8px"
                style={{ background: isSystem || isInternal ? '#f3ebb0' : '#d9f7be' }}
              >
                <PartContent contentType={contentType}>{body}</PartContent>
              </SCard>
              <Row type="flex" justify="start">
                <Text type="secondary">{moment(postedAt).format('DD/MM/YYYY, HH:mm:ss')}</Text>
              </Row>
            </TextContainer>

            <CommentsContainer className="hidden" comments={comments} span={1}>
              <Row type="flex" justify="end">
                <Col>
                  <Tooltip
                    title={
                      !isEmpty(comments)
                        ? t(
                            'components.textCommunicationContent.conversationPart.buttons.viewComments'
                          )
                        : t(
                            'components.textCommunicationContent.conversationPart.buttons.addComment'
                          )
                    }
                  >
                    <Button
                      type="link"
                      icon={<MessageOutlined />}
                      onClick={openModal}
                      size="large"
                      style={
                        !isEmpty(comments) ? { color: 'var(--primary)' } : { color: '#A6ADB4' }
                      }
                    />
                  </Tooltip>
                </Col>
              </Row>
            </CommentsContainer>
          </Row>
        )}
        <Modal
          width={768}
          visible={addingCommentCommunicationPartId === id}
          footer={false}
          onCancel={closeModal}
          closable={false}
          keyboard={!editingCommentId}
        >
          {isEmpty(comments) && (
            <Empty
              description={t('components.textCommunicationContent.conversationPart.emptyComments')}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
          <CommentsPanel
            comments={comments}
            showCommentsList
            allowCommenting={allowCommenting}
            addingComment
            allowSelectTimestamp={false}
            onCancelComment={closeModal}
            onAddCommentButton={onAddCommentButton}
            onCommentSave={onCommentSave}
            onDeleteComment={onDeleteComment}
            onUpdateComment={onUpdateComment}
            seconds={seconds}
          />
        </Modal>
      </Col>
    );
  }
);

const AvatarCol = styled(Col)`
  &.ant-col {
    width: 50px;
  }
`;

const TextContainer = styled(Col)`
  &.ant-col {
    max-width: 85%;
    text-align: ${props => (props.align ? props.align : 'left')};
  }
  &.ant-col .open + .hidden {
    display: block !important;
  }
`;

const CommentsContainer = styled.div`
  ${props => (!isEmpty(props.comments) ? 'display: block;' : '')}
  //display: ${props => (!isEmpty(props.comments) ? 'block' : 'none')};
`;

export default ConversationPart;
