import { AlertCircle, Edit, MoreVertical, Trash2 } from 'react-feather';
import { Col, Dropdown, InputNumber, Menu, Modal, Row, Tooltip, Typography } from 'antd';
import RateValues from 'components/Inputs/RateValues';
import Icon from 'components/Icon';
import { CHECKLIST_DEFINITION_STATUS, RATING_METHOD, SCALE_TYPES } from 'core/utils/constants';
import { debounce, get, isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { questionToGroupBindingsResource } from 'redux/resources/questionToGroupBindings';
import { setEditingQuestionWithBinding } from 'redux/ui/questionModal/reducer';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { MarginLeftButton, StyledCol } from './styled';

const Question = ({
  sumPercentage,
  updateBinding,
  questionId,
  bindingId,
  bindingsByIds,
  questionsByIds,
  ratingMethod,
  provided,
  currentChecklist,
  isDragging,
  deleteBinding,
  setEditingQuestionWithBinding
}) => {
  const { t } = useTranslation();

  const {
    scaleType = SCALE_TYPES.max_5.type,
    valuesDisplayType = 'numbers',
    status
  } = currentChecklist;
  const currentQuestion = get(questionsByIds, `${questionId}`, {});
  const { name, ratingValues, ratingValuesViews } = currentQuestion;

  const binding = bindingsByIds[bindingId];
  let selectedPrompt = '';
  const promptsResource = useSelector(state => state.promptsResource.byIds);
  const prompts = Object.values(promptsResource);
  if (!isEmpty(questionId)) {
    selectedPrompt = prompts.find(
      item =>
        item.questions &&
        item.questions.some(questionItem => questionItem.questionId === questionId)
    );
  }

  const handleMenuClick = e => {
    switch (e.key) {
      case 'edit':
        setEditingQuestionWithBinding({
          question: questionsByIds[questionId],
          questionToGroupBinding: binding,
          promptInfo: {
            selectedPrompt: selectedPrompt || {},
            useAi: !!selectedPrompt,
            promptRadio: selectedPrompt ? 'selectPrompt' : '',
            promptLibrary: selectedPrompt ? selectedPrompt?.id : ''
          }
        });
        break;

      case 'copy':
        // * maybe will be implemented in BE
        break;

      case 'delete':
        Modal.confirm({
          title: t('checklistsPage.question.confirmDelete.title'),
          content: t('checklistsPage.question.confirmDelete.description'),
          okText: t('checklistsPage.question.confirmDelete.ok'),
          cancelText: t('checklistsPage.question.confirmDelete.cancel'),
          okType: 'danger',
          onOk: async () => deleteBinding({ id: bindingId })
        });
        break;
      default:
        console.log();
    }
  };

  const menu = (
    <Menu onClick={e => handleMenuClick(e)}>
      <Menu.Item key="edit">
        <Icon icon={Edit} />
        <span>{t('checklistsPage.question.menu.edit')}</span>
      </Menu.Item>
      {status === CHECKLIST_DEFINITION_STATUS.DRAFT.value && (
        <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
          <Icon icon={Trash2} />
          <span>{t('checklistsPage.question.menu.delete')}</span>
        </Menu.Item>
      )}
    </Menu>
  );

  // * binding can be undefined in moment after deleting but before creating new one
  if (!binding) {
    return (
      <Col key={questionId}>
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <SCard isDragging={isDragging} rounded={false} bordered="4px">
            <Row type="flex" align="top">
              <SCol span={7} paddingTop="5px" paddingRight="16px">
                <SText strong>{name}</SText>
              </SCol>
              <StyledCol span={17} style={{ display: 'flex', alignItems: 'center' }}>
                <Row type="flex" wrap={false} justify="space-between" align="top">
                  <Col flex="auto">
                    <RateValues
                      size="medium"
                      ratingValues={ratingValues}
                      ratingValuesViews={ratingValuesViews}
                      scaleType={scaleType}
                      valuesDisplayType={valuesDisplayType}
                      disabled
                    />
                  </Col>
                  <SCol flex="none" marginLeft="40px">
                    <Dropdown overlay={menu} trigger={['click']}>
                      <MarginLeftButton icon={<Icon icon={MoreVertical} />} />
                    </Dropdown>
                  </SCol>
                </Row>
              </StyledCol>
            </Row>
          </SCard>
          {provided.placeholder}
        </div>
      </Col>
    );
  }
  const { percentage, autofailEnabled } = binding;
  const maxPercentage = sumPercentage >= 100 ? percentage || 1 : 100;
  const updatePercentage = debounce(newPercentage => {
    if (newPercentage && sumPercentage - percentage + newPercentage <= 100) {
      updateBinding({ id: bindingId, percentage: newPercentage });
    }
  }, 200);

  return (
    <Col key={questionId}>
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <SCard isDragging={isDragging} rounded={false} bordered="4px">
          <Row type="flex" align="top">
            <SCol span={7} paddingTop="5px" paddingRight="16px">
              <SText strong>{name}</SText>
            </SCol>
            <StyledCol span={17} style={{ display: 'flex', alignItems: 'center' }}>
              <SRow type="flex" wrap={false} justify="space-between" align="top" width="100%">
                <Col flex="auto">
                  {autofailEnabled && (
                    <Tooltip title={t('checklistsPage.question.tooltip.title')}>
                      <AlertCircle color="var(--red_primary)" style={{ marginRight: '8px' }} />
                    </Tooltip>
                  )}
                  <RateValues
                    size="medium"
                    ratingValues={ratingValues}
                    ratingValuesViews={ratingValuesViews}
                    scaleType={scaleType}
                    valuesDisplayType={valuesDisplayType}
                    disabled
                  />

                  {ratingMethod === RATING_METHOD.WEIGHTED && (
                    <SRow display="block" paddingTop="8px">
                      <SText marginRight="8px">{t('checklistsPage.question.weight')}</SText>
                      <InputNumber
                        min={1}
                        max={maxPercentage}
                        onChange={updatePercentage}
                        value={percentage}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                      />
                    </SRow>
                  )}
                </Col>
                <SCol flex="none" marginLeft="40px">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <MarginLeftButton icon={<Icon icon={MoreVertical} />} />
                  </Dropdown>
                </SCol>
              </SRow>
            </StyledCol>
          </Row>
        </SCard>
        {provided.placeholder}
      </div>
    </Col>
  );
};

const mapStateToProps = state => {
  return {
    bindingsByIds: state.questionToGroupBindingsResource.byIds,
    questionsByIds: state.questionsResource.byIds
  };
};

const mapDispatchToProps = {
  deleteBinding: questionToGroupBindingsResource.operations.deleteById,
  updateBinding: questionToGroupBindingsResource.operations.updateById,
  setEditingQuestionWithBinding
};

export default connect(mapStateToProps, mapDispatchToProps)(Question);
